<template>
    <div class="header-main-bar">
        <ButtonIcon
            :variant="HAMBURGER_BUTTON_VARIANT"
            :data-test-id="HAMBURGER_MENU_BUTTON"
            class="hamburger-menu-button"
            @click="onHamburgerClick()"
        >
            <Icon :icon="Hamburger" />
        </ButtonIcon>

        <BaseLink :path="mainPagePath">
            <MainLogo />
        </BaseLink>

        <HeaderSearch v-if="!isMobile" :placeholder="placeholder" class="search-wrapper" />

        <HeaderActions class="actions-wrapper" />

        <component
            :is="headerMenuComponent"
            v-if="isMobile && (isHeaderMenuOpen || isMenuRendered)"
            :is-modal-open="isHeaderMenuOpen"
            @hook:mounted="isMenuRendered = true"
        />
    </div>
</template>

<script>
import { createNamespacedHelpers, mapState } from 'vuex';

import { MODAL_HEADER_MENU } from '@configs/modals';

import { HAMBURGER_MENU_BUTTON } from '@types/AutomaticTestIDs';

import { HOME_PAGE } from '@router/paths';

import { HAMBURGER_MENU_OPEN_CLICK } from '@analytics-types/Events';

import BaseLink from '@atoms/BaseLink/BaseLink';

import MainLogo from '@molecules/MainLogo/MainLogo';

import HeaderSearch from '@header-organisms/HeaderSearch/HeaderSearch';
import HeaderActions from '@header-organisms/HeaderActions/HeaderActions';

import { Hamburger } from '@modivo-ui/icons/v2/navigation';
import { Icon } from '@modivo-ui/components/Icon/v1';
import { ButtonIcon, BUTTON_ICON_VARIANTS } from '@modivo-ui/components/ButtonIcon/v1';

const { mapGetters: mapNavigationGetters } = createNamespacedHelpers('navigation');

export default {
    name: 'HeaderMainBar',

    components: {
        BaseLink,
        MainLogo,
        ButtonIcon,
        Icon,
        HeaderActions,
        HeaderSearch,
    },

    props: {
        placeholder: {
            type: String,
            default: '',
        },
    },

    data() {
        return {
            isMenuRendered: false,
            headerMenuComponent: null,
        };
    },

    computed: {
        ...mapState(['isMobile']),
        ...mapNavigationGetters(['activeMainCategory']),

        mainPagePath() {
            return this.activeMainCategory?.url || HOME_PAGE;
        },

        isHeaderMenuOpen() {
            return this.$modals.isOpen(MODAL_HEADER_MENU);
        },
    },

    watch: {
        $route() {
            this.$modals.close(MODAL_HEADER_MENU);
        },
    },

    beforeCreate() {
        this.Hamburger = Hamburger;
        this.HAMBURGER_BUTTON_VARIANT = BUTTON_ICON_VARIANTS.TERTIARY;
        this.HAMBURGER_MENU_BUTTON = HAMBURGER_MENU_BUTTON;
    },

    methods: {
        async onHamburgerClick() {
            if (!this.headerMenuComponent) {
                this.headerMenuComponent = (
                    await import(
                        /* webpackChunkName: "header-menu" */
                        '@header-organisms/HeaderMenu/HeaderMenu'
                    )
                ).default;
            }

            this.$modals.open(MODAL_HEADER_MENU);
            this.$analytics.emit(HAMBURGER_MENU_OPEN_CLICK);
        },
    },
};
</script>

<style scoped lang="scss">
.header-main-bar {
    @apply flex items-center h-ui-13 px-ui-2;

    .actions-wrapper {
        @apply flex-grow flex justify-end;
    }

    .hamburger-menu-button {
        @apply mr-ui-2;
    }

    .search-wrapper {
        @apply hidden;
    }

    @screen lg {
        @apply px-ui-4 max-w-[1596px] mx-auto gap-x-ui-7 items-end;

        .actions-wrapper {
            @apply flex-grow-0;
        }

        .search-wrapper {
            @apply flex-grow block;
        }

        .hamburger-menu-button {
            @apply hidden;
        }

        :deep(.modivo-logo) {
            @apply w-[130px] h-[40px];
        }
    }
}
</style>
